import { useState } from "react";

import TopNav from "../components/topNav";
import SideNav from "../components/sideNav";
import FooterSection from "../components/footer";

import LinkedInLineChart from "../components/LinkedInLineChart";
import FacebookLineChart from "../components/FacebookLineChart";
import NurseOnDemandLineChart from "../components/NurseOnDemandLineChart";

import { LinkedInData } from "../data/LinkedInData";
import { FacebookData } from "../data/FacebookData";
import { NurseOnDemandData } from "../data/NurseOnDemandData";

import { IconContext } from "react-icons";
import { FaFacebook } from "react-icons/fa";
import { BiLike } from "react-icons/bi";
import { AiFillLinkedin } from "react-icons/ai";

const ReportsAndAnalytics = () => {
  const [linkedInData, setLinkedInData] = useState({
    labels: LinkedInData.map((data) => data.year),
    datasets: [
      {
        label: "Followers Gained",
        data: LinkedInData.map((data) => data.followersGain),
        backgroundColor: ["#4BC0C0"],
        borderColor: "darkGray",
        borderWidth: 2,
        pointBorderColor: "gray",
        tension: 0.5,
      },
      {
        label: "Total Followers",
        data: LinkedInData.map((data) => data.totalFollowers),
        backgroundColor: ["#0427d8"],
        borderColor: "gray",
        borderWidth: 2,
        tension: 0.5,
      },
    ],
  });

  const [facebookData, setFacebookData] = useState({
    labels: FacebookData.map((data) => data.year),
    datasets: [
      {
        label: "New Likes",
        data: FacebookData.map((data) => data.likesGain),
        backgroundColor: ["#4BC0C0"],
        borderColor: "darkGray",
        borderWidth: 2,
        pointBorderColor: "Gray",
        tension: 0.5,
      },
      {
        label: "New Followers",
        data: FacebookData.map((data) => data.followersGain),
        backgroundColor: ["#2a8282"],
        borderColor: "Gray",
        borderWidth: 2,
        pointBorderColor: "lightGray",
        tension: 0.5,
      },
      {
        label: "Total Likes",
        data: FacebookData.map((data) => data.totalLikes),
        backgroundColor: ["#2647fc"],
        borderColor: "LightSlateGray",
        borderWidth: 2,
        tension: 0.5,
      },
      {
        label: "Total Followers",
        data: FacebookData.map((data) => data.totalFollowers),
        backgroundColor: ["#0427d8"],
        borderColor: "SlateGray",
        borderWidth: 2,
        tension: 0.5,
      },
    ],
  });

  const [nurseOnDemandData, setNurseOnDemandData] = useState({
    labels: NurseOnDemandData.map((data) => data.year),
    datasets: [
      {
        label: "New Likes",
        data: NurseOnDemandData.map((data) => data.likesGain),
        backgroundColor: ["#4BC0C0"],
        borderColor: "darkGray",
        borderWidth: 2,
        pointBorderColor: "Gray",
        tension: 0.5,
      },
      {
        label: "New Followers",
        data: NurseOnDemandData.map((data) => data.followersGain),
        backgroundColor: ["#2a8282"],
        borderColor: "Gray",
        borderWidth: 2,
        pointBorderColor: "lightGray",
        tension: 0.5,
      },
      {
        label: "Total Likes",
        data: NurseOnDemandData.map((data) => data.totalLikes),
        backgroundColor: ["#2647fc"],
        borderColor: "LightSlateGray",
        borderWidth: 2,
        tension: 0.5,
      },
      {
        label: "Total Followers",
        data: NurseOnDemandData.map((data) => data.totalFollowers),
        backgroundColor: ["#0427d8"],
        borderColor: "SlateGray",
        borderWidth: 2,
        tension: 0.5,
      },
    ],
  });

  return (
    <>
      <SideNav />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <TopNav page={"Reports"} />
        <div className="container-fluid">
          <h1 className="fs-5 text-dark">Reports and Analytics</h1>
          <div className="container-fluid py-2">
            <div className="row">
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Social Media
                          </p>
                          <h5 className="font-weight-bolder mb-0 text-sm">
                            +3
                            <br />
                            <span className="text-success text-sm">
                              new posts this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://www.linkedin.com/company/trivut-corp/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.5em",
                                color: "white",
                              }}
                            >
                              <BiLike />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            LinkedIn
                          </p>
                          <h5 className="font-weight-bold mb-0 mr-1 fs-6 text-sm">
                            288 Followers
                            <br />
                            <span className="text-success text-sm font-weight-bold">
                              +1 new this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://www.linkedin.com/company/trivut-corp/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.5em",
                                color: "white",
                              }}
                            >
                              <AiFillLinkedin />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Facebook
                          </p>
                          <h5 className="font-weight-bolder mb-0 fs-6 text-sm">
                            122 Likes
                            <br />
                            <span className="text-success text-sm font-weight-bold">
                              no new this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.4em",
                                color: "white",
                              }}
                            >
                              <FaFacebook />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Facebook
                          </p>
                          <h5 className="font-weight-bolder mb-0 fs-6 text-sm">
                            169 Followers
                            <span className="text-success text-sm font-weight-bold">
                              &nbsp; no new this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.4em",
                                color: "white",
                              }}
                            >
                              <FaFacebook />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Nurse On-Demand Posts
                          </p>
                          <h5 className="font-weight-bolder mb-0 text-sm">
                            no
                            <br />
                            <span className="text-success text-sm font-weight-bold">
                              new posts this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://www.linkedin.com/company/trivut-corp/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.5em",
                                color: "white",
                              }}
                            >
                              <FaFacebook />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Nurse On-Demand
                          </p>
                          <h5 className="font-weight-bolder mb-0 mr-1 fs-6 text-sm">
                            41 Likes
                            <br />
                            <span className="text-success text-sm font-weight-bold">
                              no new this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://www.linkedin.com/company/trivut-corp/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.5em",
                                color: "white",
                              }}
                            >
                              <FaFacebook />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Nurse On-Demand
                          </p>
                          <h5 className="font-weight-bolder mb-0 fs-6 text-sm">
                            144 Followers
                            <br />
                            <span className="text-success text-sm font-weight-bold">
                              no new this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.4em",
                                color: "white",
                              }}
                            >
                              <FaFacebook />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-sm-6">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Facebook
                          </p>
                          <h5 className="font-weight-bolder mb-0 fs-6">
                            103 Followers
                            <span className="text-success text-sm font-weight-bolder">
                              &nbsp; +2 new this week
                            </span>
                          </h5
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.4em",
                                color: "white",
                              }}
                            >
                              <FaFacebook />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                          Active DevJedis
                        </p>
                        <h5 className="font-weight-bolder mb-0">
                          7
                          <span className="text-success text-sm font-weight-bolder">
                            &nbsp; no new trainee
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                        <a
                          href="https://t.me/+ut0icVlW8wFjNGJh"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconContext.Provider
                            value={{
                              size: "1.3em",
                              color: "white",
                            }}
                          >
                            <FaUsers />
                          </IconContext.Provider>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <h5 className="mt-3">
            <IconContext.Provider
              value={{
                size: "1.8em",
                color: "#0077b5",
              }}
            >
              <AiFillLinkedin />
            </IconContext.Provider>
            Trivut Corp. LinkedIn
          </h5>
          <div
            style={{
              width: "900px",
              backgroundColor: "#f2f4f7",
            }}
          >
            <LinkedInLineChart chartData={linkedInData} />
          </div>
          <h5 className="mt-4">
            <IconContext.Provider
              value={{
                size: "1.7em",
                color: "#3b5998",
              }}
            >
              <FaFacebook />
            </IconContext.Provider>
            &nbsp;Trivut Corp. Facebook
          </h5>
          <div
            style={{
              width: "900px",
              // backgroundColor: "#f2f4f7",
              backgroundColor: "#e9eef7",
            }}
          >
            <FacebookLineChart chartData={facebookData} />
          </div>
          <h5 className="mt-4">
            <IconContext.Provider
              value={{
                size: "1.7em",
                color: "#3b5998",
              }}
            >
              <FaFacebook />
            </IconContext.Provider>
            &nbsp;Trivut's Nurse On-Demand
          </h5>
          <div
            style={{
              width: "900px",
              // backgroundColor: "#f2f4f7",
              backgroundColor: "#e9eef7",
            }}
          >
            <NurseOnDemandLineChart chartData={nurseOnDemandData} />
          </div>
        </div>
        <FooterSection />
      </main>
    </>
  );
};

export default ReportsAndAnalytics;
