import TopNav from "../components/topNav";
import SideNav from "../components/sideNav";
import FooterSection from "../components/footer";

const Calendar = () => {
  return (
    <>
      <SideNav />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <TopNav page={"Calendar"} />
        <div className="container-fluid">
          <h1 className="fs-5 text-dark">Calendar</h1>
          <h2 className="fs-5 text-dark fw-bolder">SSL Certificate Expiry</h2>
          <div className="row">
            <div class="card col-5 m-1">
              <div class="card-body">
                <h5 class="card-title">Trivut Corporate Site</h5>
                <p class="card-text">
                  <a href="https://trivut.com/" target="_blank">
                    https://trivut.com/
                  </a>
                </p>
                <p>
                  Expiry:{" "}
                  <div class="alert alert-success" role="alert">
                    Sunday <br />
                    <strong>October 6, 2024 at 10:46:37 AM</strong>
                  </div>
                </p>
                <a
                  href="https://www.trivut.com"
                  class="btn btn-primary"
                  rel="noreferrer"
                  target="_blank"
                >
                  Go to site
                </a>
              </div>
            </div>
            <div class="card col-5 m-1">
              <div class="card-body">
                <h5 class="card-title">Trivut Document Tracking System</h5>
                <p class="card-text">
                  <a href="https://dts.trivut.app/" target="_blank">
                    https://dts.trivut.app/
                  </a>
                </p>
                <p>
                  Expiry:{" "}
                  <div class="alert alert-success" role="alert">
                    Sunday <br />
                    <strong>October 6, 2024 at 11:03:58 AM</strong>
                  </div>
                </p>
                <a
                  href="https://dts.trivut.app/"
                  class="btn btn-primary"
                  rel="noreferrer"
                  target="_blank"
                >
                  Go to site
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="card col-5 m-1">
              <div class="card-body">
                <h5 class="card-title">Trivut Marketplace</h5>
                <p class="card-text">
                  <a href="https://marketplace.trivut.com/" target="_blank">
                    https://marketplace.trivut.com/
                  </a>
                </p>
                <p>
                  Expiry:{" "}
                  <div class="alert alert-success" role="alert">
                    Sunday <br />
                    <strong>October 6, 2024 at 10:49:47 AM</strong>
                  </div>
                </p>
                <a
                  href="https://marketplace.trivut.com/"
                  class="btn btn-primary"
                  rel="noreferrer"
                  target="_blank"
                >
                  Go to site
                </a>
              </div>
            </div>
            <div class="card col-5 m-1">
              <div class="card-body">
                <h5 class="card-title">Trivut Ideas</h5>
                <p class="card-text">
                  <a href="https://ideas.trivut.com/" target="_blank">
                    https://ideas.trivut.com/
                  </a>
                </p>
                <p>
                  Expiry:{" "}
                  <div class="alert alert-success" role="alert">
                    Sunday <br />
                    <strong>October 6, 2024 at 10:59:27 AM</strong>
                  </div>
                </p>
                <a
                  href="https://ideas.trivut.com/"
                  class="btn btn-primary"
                  rel="noreferrer"
                  target="_blank"
                >
                  Go to site
                </a>
              </div>
            </div>
          </div>
        </div>
        <FooterSection />
      </main>
    </>
  );
};

export default Calendar;
